import React, { useEffect, useState } from "react";
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import mixpanel from "mixpanel-browser";
import Loader from '../Loader'
import InfoImage from '../../images/info.svg'
import "./styles.scss";

const LEAD_ID = 'ab-template-affiliate-new-single-form';
mixpanel.init(process.env.GATSBY_MIXPANEL_TOKEN);

const SingleForm = (props) => {
  const { 
    title, 
    subTitle,
    fields, 
    nextBtnLabel, 
    onSubmit,
    hideContinue,
    image,
    discountCode,
  } = props;

  const [loading, setLoading] = useState(false);
  const [focus, setFocus] = useState('');
  const [formValues, setFormValues] = useState({})

  const handleChange = field => (e) => {
    if (field === 'zipCode' && !/^\d+$/.test(e.target.value)) {
      return
    }
    setFormValues({
      ...formValues,
      [field]: e.target.value
    })
  }

  const handleEnter = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      if (loading || !isFilled()) {
        return;
      }
      handleNext()
    }
  }

  const handleNext = async () => {
    const validations = []
    fields.forEach(async field => {
      const { validate, name } = field;
      if (validate) {
        validations.push(validate(formValues[name] || ''))
      }
    })
    let validResults;
    if (validations.length) {
      setLoading(true);
      validResults = await Promise.all(validations)
    }
    if (validations.length && validResults.includes(false)) {
      setLoading(false);
      toast.error("Something doesn't look right. Please double check your entries and try again.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } else {
      if (fields.some(f => f.name === 'zipCode')) {
        mixpanel.track("Entered Zipcode", {
          version: LEAD_ID,
          affiliateCode: discountCode,
          origin: "hero"
        });
      }
      if (fields.some(f => f.name === 'firstName')) {
        mixpanel.track("Input name", {
          version: LEAD_ID,
          affiliateCode: discountCode,
          origin: "hero"
        });
      }
      setLoading(true);
      await onSubmit(formValues);
      setLoading(false);
    }
  }

  const isFilled = () => {
    let filled = true;
    fields.forEach(field => {
      const { name, type } = field;
      filled = filled && (!!formValues[name] && type === 'text')
    })
    return filled
  }

  useEffect(() => {
    fields.forEach(field => {
      const { name, initialValue } = field;
      if (initialValue) {
        setFormValues({
          ...formValues,
          [name]: initialValue
        })
      }
    })
  }, [fields])

  return (
    <div className='signle-form'>
      {image && (
        <div className="img-wrapper">
          <img src={image} alt='' />
        </div>
      )}
      {title && <div className="title">{title}</div>}
      {subTitle && <div className="title sub">{subTitle}</div>}
      <div className="fields">
        {fields.map((field, index) => {
          const { label, name, type, inputmode, hideLabel, maskFormat, stickyLabel, otherProps, tooltipText, width, validate } = field;
          const isFirst = index === 0;
          return (
            <div key={index} className="field-wrapper" style={width && {width}}>
              {!hideLabel && (
                <label>
                  {label}
                </label>
              )}
              {stickyLabel && focus === name && <label className="sticky-label">{stickyLabel}</label>}
              {maskFormat ? (
                <InputMask
                  mask={maskFormat}
                  type={type}
                  inputMode={inputmode || 'text'}
                  name={name}
                  value={formValues[name] || ''}
                  onChange={handleChange(name)}
                  beforeMaskedValueChange={(newState, oldState, userInput) => {
                    var { value } = newState;
                    var selection = newState.selection;
                    var cursorPosition = selection ? selection.start : null;

                    if (userInput === '1' && selection.start === 2 && selection.end === 2) {
                      cursorPosition--;
                      selection = { start: cursorPosition, end: cursorPosition };
                      value = value.split('');
                      value[1] = '_';
                      value = value.join('');
                    }

                    return {
                      value,
                      selection
                    };
                  }}
                  className="input"
                  placeholder={hideLabel ? label : ''}
                  style={(!!stickyLabel && focus === name) ? { paddingLeft: 27 } : {}}
                  onFocus={() => setFocus(name)}
                  {...otherProps}
                >
                  {(inputProps) => <input {...inputProps} autoFocus={isFirst} onKeyUp={handleEnter} />}
                </InputMask>
              ) : (
                  <input
                    type={type}
                    name={name}
                    inputMode={inputmode || 'text'}
                    value={formValues[name] || ''}
                    onChange={handleChange(name)}
                    className="input"
                    placeholder={hideLabel ? label : ''}
                    autoFocus={isFirst}
                    onKeyUp={handleEnter}
                    onFocus={() => setFocus(name)}
                    onBlur={() => formValues[name] && formValues[name].length > 4 && validate(formValues[name])}
                    {...otherProps}
                  />
                )}
              {tooltipText && (
                <div className={`tooltip ${name === 'zipCode' ? 'zip' : ''}`}>
                  <img src={InfoImage} alt="tooltip" className="tooltip-icon" />
                  <span className="tooltiptext">{tooltipText}</span>
                </div>
              )}
            </div>
          )
        })}
      </div>
      <div className="actions">
        {!hideContinue && (
          <button onClick={handleNext} className="continue-btn" disabled={loading || !isFilled()}>
            {loading ? (
              <Loader size={32} />
            ) : nextBtnLabel || 'CONTINUE'}
          </button>
        )}
      </div>
    </div>
  );
}

export default SingleForm;
